<!-- 顶部导航 -->
<template>
	<div class="header-wrap">
		<div v-if="showProduct || showSolution" class="shade"></div>
		<div class="header-logo" @click="handleCommand('/home')">
			<img
				v-if="isMobileEnd"
				v-lazy="headerMobileLogo"
				:key="headerMobileLogo"
				alt="logo"
			/>
			<img
				v-else
				v-lazy="headerLogoBlank"
				:key="headerLogoBlank"
				alt="logo"
			/>
		</div>
		<div class="header-navMenu">
			<el-link
				class="header-navMenu-item"
				:underline="false"
				@click="handleCommand('/home')"
				>首页</el-link
			>
			<div
				class="header-navMenu-item"
				@mouseover="showProduct = true"
				@mouseout="showProduct = false"
			>
				<span class="item-title">
					产品中心<i class="iconfont icon-xiala-shang shang"></i>
					<i class="iconfont icon-xiala-xia xia"></i>
				</span>
				<div class="item-product" v-show="showProduct">
					<div class="item-product-content">
						<div
							v-for="(item, index_p) in seriesList"
							class="item-type"
							:style="{
								'grid-row': `span ${
									item.productList.length >= 3
										? item.productList.length + 2
										: 4
								}`,
							}"
							:class="`item${index_p + 1}`"
							:key="index_p"
						>
							<div class="item-type-title">
								<div class="title-img">
									<img v-lazy="item.seriesIcon" alt="" />
								</div>
								<div class="title-name">
									{{ item.seriesName }}
								</div>
							</div>
							<div class="item-type-list">
								<el-link
									:underline="false"
									v-for="(i, index) in item.productList"
									:key="index"
									@click="
										handleCommand(`/product?id=${i.id}`)
									"
									>{{ i.name
									}}<el-tag v-if="i.mark"
										>NEW</el-tag
									></el-link
								>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="header-navMenu-item"
				@mouseover="showSolution = true"
				@mouseout="showSolution = false"
			>
				<span class="item-title">
					解决方案<i class="iconfont icon-xiala-shang shang"></i>
					<i class="iconfont icon-xiala-xia xia"></i>
				</span>
				<div class="item-solution" v-show="showSolution">
					<div class="item-solution-content">
						<div class="menu-last">
							<el-menu
								:default-active="activeIndex"
								@select="solutionSelect"
							>
								<el-menu-item
									:index="index_p.toString()"
									v-for="(item, index_p) in solutionList"
									:key="index_p"
								>
									<span slot="title">
										{{ item.professionName }}
									</span>
								</el-menu-item>
							</el-menu>
						</div>
						<div class="menu-right">
							<div
								v-for="(item, index_f) in currentFieldList"
								class="item-type"
								:style="{
									'grid-row': `span ${
										item.planList.length >= 5
											? item.planList.length + 2
											: 5
									}`,
								}"
								:class="`item${index_f + 1}`"
								:key="index_f"
							>
								<div class="item-type-title">
									{{ item.fieldName }}
								</div>
								<div class="item-type-list">
									<el-link
										:underline="false"
										v-for="(i, index) in item.planList"
										:key="index"
										@click="
											handleCommand(
												`/solution?id=${i.id}`
											)
										"
										>{{ i.name }}
										<el-tag v-if="i.mark"
											>HOT</el-tag
										></el-link
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<el-link
				class="header-navMenu-item"
				:underline="false"
				@click="handleClickOpen(openPlatformUrl, '_blank')"
				>开放平台</el-link
			>
			<div
				class="header-navMenu-item"
				@mouseover="showAbout = true"
				@mouseout="showAbout = false"
			>
				<span class="item-title">
					关于我们<i class="iconfont icon-xiala-shang shang"></i>
					<i class="iconfont icon-xiala-xia xia"></i>
				</span>
				<div class="item-about-content" v-show="showAbout">
					<div
						class="item-type"
						v-for="(i, index) in AboutUsList"
						:key="index"
						@click="handleCommand(i.keyPath)"
					>
						<el-link :underline="false">{{ i.keyName }}</el-link>
					</div>
				</div>
			</div>
		</div>
		<div class="header-iconMenu" v-if="isMobileEnd">
			<MobileTopNav ref="mobileTopNavRef" />
		</div>
	</div>
</template>

<script>
import MobileTopNav from './MobileTopNav.vue'
import { menuAboutUs } from '@/utils/mock.js'
export default {
	name: 'NewsInfo',
	data() {
		return {
			openPlatformUrl: window.openPlatformUrl,
			isSidebar: false,
			activeName: '1',
			headerLogoBlank: require('@/assets/img/jetvoice2.png'),
			headerMobileLogo: require('@/assets/img/jetvoice3.png'),
			AboutUsList: menuAboutUs,
			htmlWidth: '',
			mobileTerminal: false,
			showProduct: false,
			showSolution: false,
			showAbout: false,
			activeIndex: '0',
			fieldList: [],
		}
	},
	props: {},
	watch: {
		activeIndex(newVal) {
			const index = parseInt(newVal)
			if (this.solutionList && this.solutionList[index]) {
				this.fieldList = this.solutionList[index].fieldList
			}
		},
	},
	created() {},
	mounted() {
		if ((this, this._isMobile())) {
			this.mobileTerminal = true
		} else {
			this.mobileTerminal = false
		}
		this.htmlWidth = document.querySelector('html').offsetWidth
	},
	methods: {
		//pc端解决方案切换行业
		solutionSelect(key) {
			this.activeIndex = key
		},
		handleCommand(command) {
			this.$router.push(command)
			if (command == '/home') {
				this.$refs.mobileTopNavRef.handleHomeImg()
			}
		},
		handleClickOpen(url, type) {
			window.open(url, type)
		},
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
			return flag
		},
	},
	components: {
		MobileTopNav,
	},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
		seriesList() {
			return this.$store.state.seriesList
		},
		solutionList() {
			return this.$store.state.solutionList
		},
		currentFieldList() {
			let num = parseInt(this.activeIndex)
			if (this.solutionList && this.solutionList[num]) {
				return this.solutionList[num].fieldList || []
			}
			return []
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.header-wrap {
	width: 100%;
	height: 66px;
	box-sizing: border-box;
	background-color: #ffffff;
	box-shadow: 0px 1px 0px 0px rgba(204, 204, 204, 0.7);
	padding-left: 16.9271vw;
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	.shade {
		position: fixed;
		top: 66px;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 11;
		background-color: rgba(0, 0, 0, 0.45);
	}

	.header-logo {
		// height: 48px;
		box-sizing: border-box;
		margin-right: 37px;
		z-index: 12;
		img {
			width: inherit;
			height: 100%;
			object-fit: cover;
			display: block;
		}
	}
	.header-navMenu {
		height: 100%;
		z-index: 99;
		display: flex;
		align-items: center;
		position: relative;
		&-item {
			height: 100%;
			font-weight: 400;
			font-size: 16px;
			color: #333333;
			padding: 0 18px;
			box-sizing: border-box;
			text-align: center;
			.item-title {
				display: inline-block;
				height: 100%;
				line-height: 66px;
				cursor: pointer;
				i {
					font-size: 14px;
					margin-left: 4px;
				}
				.xia {
					display: inline-block;
				}
				.shang {
					display: none;
				}
			}
			&:hover {
				color: #1d67e8;
				.xia {
					display: none;
				}
				.shang {
					display: inline-block;
				}
			}
			//产品中心，下拉分类
			.item-product {
				position: fixed;
				top: 66px;
				left: 0;
				width: 100vw;
				height: auto; //405 - 26
				min-height: 405px;
				background-color: #ffffff;
				border-top: 1px solid rgba(204, 204, 204, 0.7);
				padding: 0 16.9271vw;
				box-sizing: border-box;
				.item-product-content {
					width: 66.1458vw; //100vw - 33.8542(650px)
					height: auto; //405 - 26
					min-height: 405px;
					padding: 26px 0 26px 0;
					box-sizing: border-box;
					// column-width: 200px;
					// column-count: 3;
					// column-gap: 30px;
					display: grid;
					grid-template-columns: repeat(auto-fill, 200px);
					grid-auto-rows: 28px;
					gap: 5px 30px;
					max-height: 500px;
					overflow-y: auto;
					overflow-x: hidden;
					.item-type {
						min-height: 200px;
						break-inside: avoid; //避免在元素内部断行并产生新列
						.item-type-title {
							display: flex;
							padding: 0 0 8px 0; //8px
							border-bottom: 1px solid rgba(204, 204, 204, 0.7);
							margin-bottom: 4px; //4px
							.title-img {
								width: 20px;
								height: 20px;
								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}
							.title-name {
								font-weight: 400;
								font-size: 16px; //16px
								color: #1d67e8;
								line-height: 22px; //22px
								text-align: left;
								margin-left: 8px;
							}
						}
						.item-type-list {
							text-align: left;
							.el-link {
								display: block;
								margin-bottom: 12px; //6px
								:deep(.el-link--inner) {
									font-family: Source Han Sans,
										Source Han Sans;
									font-weight: 400;
									font-size: 14px;
									color: #333333;
									line-height: 22px;
								}
								.el-tag {
									width: 35px;
									height: 16px;
									background: rgba(29, 103, 232, 0.15);
									border-radius: 4px;
									padding: 0;
									text-align: center;
									align-content: center;
									margin-left: 4px;
									font-family: Source Han Sans,
										Source Han Sans;
									font-weight: 400;
									font-size: 12px;
									color: #1d67e8;
									line-height: 14px;
								}
								&:hover {
									color: #1d67e8;
								}
								&:first-child {
									margin-top: 16px;
								}
							}
						}
					}
				}
			}
			//解决方案，下拉分类
			.item-solution {
				position: fixed;
				top: 66px;
				left: 0;
				width: 100vw;
				height: auto; //405 - 26
				min-height: 400px;
				background-color: #ffffff;
				border-top: 1px solid rgba(204, 204, 204, 0.7);
				padding: 0 16.9271vw;
				box-sizing: border-box;
				.item-solution-content {
					width: 66.1458vw; //100vw - 33.8542(650px)
					height: auto; //405 - 26
					min-height: 400px;
					background-color: #ffffff;
					display: flex;
					// align-items: center;
					.menu-last {
						width: 148px;
						background-color: #f6f9fb;
						.el-menu {
							background-color: transparent;
							border-right: none;
							::v-deep .el-menu-item {
								text-align: left;
								align-content: center;
								span {
									display: inline-block;
									height: calc(100% - 24px); //34px
									// width: 44px;
									font-size: 16px;
									line-height: 34px;
									border-bottom: 2px solid transparent;
								}
							}
							.el-menu-item.is-active {
								span {
									color: #1d67e8;
									border-bottom-color: #1d67e8;
								}
							}
							.el-menu-item:focus,
							.el-menu-item:hover {
								background-color: transparent;
								span {
									color: #1d67e8;
									border-bottom-color: #1d67e8;
								}
							}
						}
					}
					.menu-right {
						width: calc(100% - 148px);
						height: auto;
						min-height: 25.5208vw;
						padding: 24px 30px;
						box-sizing: border-box;
						// column-width: 200px;
						// column-count: 4;
						// column-gap: 30px;
						display: grid;
						// grid-template-columns: repeat(4, minmax(200px, 1fr));
						grid-template-columns: repeat(auto-fill, 200px);
						grid-auto-rows: 28px;
						gap: 10px 30px;
						max-height: 500px;
						overflow-y: auto;
						overflow-x: hidden;

						.item-type {
							// min-height: 180px;
							break-inside: avoid; //避免在元素内部断行并产生新列
							.item-type-title {
								font-weight: 400;
								font-size: 16px; //16px
								color: #1d67e8;
								line-height: 22px; //22px
								text-align: left;
								padding: 0 0 8px 0; //8px
								border-bottom: 1px solid
									rgba(204, 204, 204, 0.7);
								margin-bottom: 4px; //4px
							}
							.item-type-list {
								text-align: left;
								.el-link {
									display: block;
									margin-bottom: 12px; //6px
									font-family: Source Han Sans,
										Source Han Sans;
									font-weight: 400;
									font-size: 14px;
									color: #333333;
									line-height: 22px;
									.el-tag {
										width: 35px;
										height: 16px;
										line-height: 14px;
										color: #fa5151;
										background-color: rgba(
											250,
											81,
											81,
											0.2
										);
										border-color: transparent;
										border-radius: 4px;
										padding: 0;
										text-align: center;
										margin-left: 4px;
									}
									&:hover {
										color: #1d67e8;
									}
									&:first-child {
										margin-top: 16px;
									}
								}
							}
						}
					}
				}
			}
			//关于我们，下拉分类
			.item-about-content {
				border-top: 1px solid rgba(204, 204, 204, 0.7);
				.item-type {
					background-color: #ffffff;
					padding: 10px;
					box-sizing: border-box;
					:deep(.el-link--inner) {
						color: #333333;
						height: 20px;
						line-height: 20px;
					}
					&:hover {
						:deep(.el-link--inner) {
							color: #0078ff;
						}
					}
				}
			}
			.el-dropdown-link {
				i {
					display: inline-block;
					margin-left: 0.08rem;
				}
			}
		}
	}
}
@media screen and (max-width: 1200px) {
	.header-wrap {
		display: flex;
		justify-content: space-between;
		padding: 0 10px 0 10px;
		.header-navMenu {
			display: none;
		}
		.header-iconMenu {
			display: block;
		}
	}
}

@media screen and (max-width: 768px) {
	.header-wrap {
		height: 44px;
		box-shadow: none;
		.header-logo {
			height: 34px;
			padding: 0 5px;
		}
		.header-iconMenu {
			i {
				font-size: 14px;
			}
			.header-sidebar {
				width: 100%;
				top: 44px;
				.sidebar {
					::v-deep .el-menu-item,
					.el-submenu__title {
						font-size: 14px;
					}
				}
			}
		}
	}
}
</style>
