<!-- 产品中心-应用场景 -->
<template>
	<div class="swiper-warp">
		<div class="swiper-container" id="swiper1">
			<div class="swiper-wrapper">
				<div
					class="swiper-slide swiper1-slide"
					v-for="(item, index) in scenesList"
					:key="index"
				>
					<div
						class="gallery-item-name"
						:class="{ videoActive: item.isMp4 }"
					>
						{{ item.sceneName }}
					</div>
					<div class="gallery-item-desc" @click="handleFormSub">
						立即咨询 <span>></span>
					</div>
				</div>
			</div>
			<div class="swiper-pagination"></div>
		</div>
		<div class="swiper-container" id="swiper2">
			<div class="swiper-wrapper swiper2-wrapper">
				<div
					class="swiper-slide scene-item"
					v-for="(scene, indez) in scenesList"
					:key="indez"
				>
					<div class="scene-item-img">
						<video
							:id="'video' + indez"
							controls
							playsinline
							v-if="scene.isMp4"
						>
							<!-- MP4 文件 -->
							<source :src="scene.sceneImg" type="video/mp4" />
						</video>
						<img v-else :src="scene.sceneImg" alt="应用场景" />
					</div>
				</div>
			</div>
			<!-- Add Arrows -->
			<div class="swiper-button-next"></div>
			<div class="swiper-button-prev"></div>
		</div>
	</div>
</template>

<script>
// 引入js
import Swiper from 'swiper'
// 引入css
import 'swiper/css/swiper.min.css'
export default {
	name: 'AppScenario',
	data() {
		return {
			xSwiper: null,
		}
	},
	props: {
		scenesList: {
			type: Array,
			default: () => [],
		},
	},
	watch: {
		// 解决路由跳转同一个页面，携带不同参数，页面不刷新问题
		$route(to, from) {
			if (to.query.id != from.query.id) {
				setTimeout(() => {
					this.xSwiper.destroy(true, true) // 第一个参数为删除DOM元素，第二个参数为清除事件绑定
					this.myswiper()
				}, 500)
			}
		},
	},
	created() {},
	mounted() {
		if (this.scenesList.length == 5) {
			// this.$nextTick(() => {
			// 	this.myswiper()
			// })
			setTimeout(() => {
				this.myswiper()
			}, 500)
		}
	},
	methods: {
		handleFormSub() {
			this.$router.push('/formSub')
		},
		myswiper: function () {
			this.xSwiper = new Swiper('#swiper1', {
				loop: true,
				slidesPerView: 5, //设置slider容器能够同时显示的slides数量(carousel模式)。
				// spaceBetween: 30, //slide之间的距离（单位px）
				centeredSlides: true, //设定为true时，活动块会居中，而不是默认状态下的居左。
				loopedSlides: 10, //在loop模式下使用slidesPerview:'auto',还需使用该参数设置所要用到的loop个数。
				slideToClickedSlide: true, //设置为true则点击slide会过渡到这个slide
				allowTouchMove: false, //是否允许触摸滑动
				observer: true, //修改swiper自己或子元素时，自动初始化swiper
				observeParents: true, //修改swiper的父元素时，自动初始化swiper
				on: {
					click: (swiper) => {
						let classList = swiper.target.classList

						if (this.scenesList.length > 0) {
							let videoDoms = [] // 缓存所有视频 DOM 元素
							this.scenesList.forEach((item, index) => {
								if (item.isMp4) {
									let doms = document.querySelectorAll(
										'#video' + index
									)
									videoDoms.push(...doms) // 缓存视频 DOM 元素
								}
								videoDoms.forEach((dom) => {
									if (classList.contains('videoActive')) {
										dom.style.display = 'block'
									} else {
										dom.style.display = 'none'
									}
								})
							})
						}
					},
				},
			})
			var Swiper2 = new Swiper('#swiper2', {
				effect: 'fade',
				centeredSlides: true, //设定为true时，活动块会居中，而不是默认状态下的居左。
				loop: true,
				loopedSlides: 5,
				observer: true, //修改swiper自己或子元素时，自动初始化swiper
				observeParents: true, //修改swiper的父元素时，自动初始化swiper
				allowTouchMove: false, //是否允许触摸滑动
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				on: {
					click: () => {
						if (this.scenesList.length > 0) {
							this.scenesList.forEach((item, index) => {
								if (item.isMp4) {
									let doms = document.querySelectorAll(
										'#video' + index
									)
									console.log('s2', doms)
									//点击轮播时，会复制第一个dom,所以判断如果有两个都暂停
									doms[0].pause()
									if (doms.length > 1) doms[1].pause()
									// 展示 doms 中的 DOM 元素
									doms.forEach((dom) => {
										dom.style.display = 'block'
									})
								}
							})
						}
					},
				},
			})
			this.xSwiper.controller.control = Swiper2 //Swiper1控制Swiper2，需要在Swiper2初始化后
			Swiper2.controller.control = this.xSwiper //Swiper1控制Swiper2，需要在Swiper2初始化后
		},
	},
	components: {},
	computed: {},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.swiper-warp {
	.swiper-container {
		width: 100%;
		height: 100%;
	}
	.swiper-slide {
		text-align: center;
		font-size: 0.18rem;
		background: #fff;
	}
	#swiper1 {
		margin-bottom: 0.6rem;
	}
	.swiper1-slide {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.gallery-item-name {
		width: 1.28rem;
		height: 0.4rem;
		font-size: 0.24rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 400;
		color: #4d5361;
		line-height: 0.32rem;
		opacity: 0.5;
		cursor: pointer;
	}
	.swiper-slide-active .gallery-item-name {
		width: 1.28rem;
		height: 0.4rem;
		font-size: 0.32rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 400;
		color: #4d5361;
		line-height: 0.4rem;
		opacity: 1;
	}

	.gallery-item-desc {
		width: 0.72rem;
		font-size: 0.14rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 400;
		color: #2185ff;
		line-height: 0.22rem;
		display: inline-block;
		padding-top: 0.08rem;
		transition-duration: 1.2s;
		display: none;
	}
	.swiper-slide-prev .gallery-item-desc {
		transform: translateX(-70px);
	}
	.swiper-slide-next .gallery-item-desc {
		transform: translateX(70px);
	}
	.swiper-slide-active .gallery-item-desc {
		display: block;
		transform: translateX(0);
		cursor: pointer;
	}

	.swiper2-wrapper {
		// width: 9rem;
		height: 5.6rem;
		.scene-item {
			display: flex;
			justify-content: center;

			.scene-item-img {
				width: 9rem;
				height: 5.6rem;
				img {
					width: 100%;
					height: 100%;
				}
				video {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	.swiper-button-prev,
	.swiper-button-next {
		width: 1.2rem;
		height: 1.2rem;
		background-color: #f1f2f4;
		border-radius: 50%;
		color: #444444;
		top: 2rem;
		&::after {
			font-size: 0.4rem;
		}
	}
}
@media screen and (max-width: 768px) {
	.swiper-warp {
		// .swiper-slide {
		// 	font-size: 14px;
		// }
		.gallery-item-name {
			width: 56px;
			height: 22px;
			font-size: 14px;
			line-height: 22px;
		}
		.swiper-slide-active .gallery-item-name {
			width: 56px;
			height: 22px;
			font-size: 14px;
			line-height: 22px;
		}
		.swiper-slide-active .gallery-item-desc {
			opacity: 0;
		}

		.swiper2-wrapper {
			height: 213px;
			.scene-item {
				align-items: center;
				border-radius: 6px;
				overflow: hidden;

				.scene-item-img {
					width: 100%;
					height: 100%;
					border-radius: 6px;
					overflow: hidden;
				}
			}
		}
		.swiper-button-prev,
		.swiper-button-next {
			top: 106px;
		}
	}
}
</style>
