<!-- 首页-成功案例 -->
<template>
	<div class="case-warp">
		<div class="title">
			<span class="title-text">成功案例</span>
		</div>
		<div class="case" v-if="!isMobileEnd">
			<div class="container">
				<el-carousel
					ref="carousel"
					trigger="click"
					:autoplay="false"
					height="527px"
					indicator-position="none"
					arrow="never"
				>
					<el-carousel-item v-for="item in caseList" :key="item.id">
						<div class="card">
							<div class="card-img">
								<img
									v-lazy="item.illustrationImg"
									alt="案例配图"
								/>
							</div>
							<div class="card-content-bg">
								<div class="card-content-center">
									<div class="card-content">
										<div class="card-content-logo">
											<img
												v-lazy="item.logoImg"
												alt="公司logo"
											/>
										</div>
										<div class="card-content-name">
											{{ item.name }}
										</div>
										<div class="card-content-desc">
											{{ item.describes }}
										</div>
										<div class="card-content-keywordList">
											<div
												class="keyword"
												v-for="(
													key, index
												) in item.keywordList"
												:key="index"
											>
												<img
													v-lazy="key.img"
													alt="关键词图片"
												/>
												<span>{{ key.word }}</span>
											</div>
										</div>
										<div class="card-content-btn">
											<div
												class="btn-left"
												@click="prevSlide"
											>
												<i
													class="iconfont icon-zuo-copy"
												></i>
											</div>
											<div
												class="btn-right"
												@click="nextSlide"
											>
												<i
													class="iconfont icon-xiala-shang-copy"
												></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<div class="case-768-content" v-else>
			<div class="container">
				<el-carousel
					ref="carousel"
					trigger="click"
					height="416px"
					:interval="5000"
					indicator-position="none"
					arrow="never"
				>
					<el-carousel-item v-for="item in caseList" :key="item.id">
						<div class="card">
							<div class="card-img">
								<img
									v-lazy="item.illustrationImg"
									alt="案例配图"
								/>
							</div>
							<div class="card-content-center">
								<div class="card-content">
									<div class="card-content-logo">
										<img
											v-lazy="item.logoImg"
											alt="公司logo"
										/>
									</div>
									<div class="card-content-name">
										{{ item.name }}
									</div>
									<div class="card-content-desc">
										{{ item.describes }}
									</div>
									<div class="card-content-keywordList">
										<div
											class="keyword"
											v-for="(
												key, index
											) in item.keywordList"
											:key="index"
										>
											<img
												v-lazy="key.img"
												alt="关键词图片"
											/>
											<span>{{ key.word }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</div>
</template>

<script>
import { getHomeCaseList } from '@/network/home/index'
export default {
	name: 'SuccessfulCase',
	data() {
		return {
			caseList: [],
		}
	},
	watch: {},
	created() {},
	mounted() {
		this._getHomeCaseList()
	},
	methods: {
		prevSlide() {
			this.$refs.carousel.prev()
		},
		nextSlide() {
			this.$refs.carousel.next()
		},
		_getHomeCaseList() {
			getHomeCaseList(this.params).then((res) => {
				if (res.data.code == 200) {
					const { list } = res.data.data
					this.caseList = list.map((item) => {
						let obj = Object.assign(item, {
							logoImg: window.baseUrl + item.logoImg,
							illustrationImg:
								window.baseUrl + item.illustrationImg,
						})
						if (Array.isArray(item.keywordList)) {
							obj.keywordList = item.keywordList.map(
								(keywordItem) => {
									// 拼接 img 字段
									return Object.assign(keywordItem, {
										img: window.baseUrl + keywordItem.img,
									})
								}
							)
						}
						return obj
					})
				}
			})
		},
	},
	components: {},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
	},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.case-warp {
	margin-bottom: 80px;
	.case {
		.container {
			width: 100%;

			.card {
				width: 100%;
				// height: 450px;
				padding-top: 77px;
				position: relative;
				.card-img {
					width: 41.9792vw;
					height: 480px;
					position: absolute;
					left: 0;
					top: 0;
					overflow: hidden;
				}
				.card-img {
					border-radius: 0 15px 15px 0;
					overflow: hidden;
					img {
						width: 100%;
						height: 100%;
						object-fit: inherit; //设置默认值，显示效果与fill相同
					}
				}
				.card-content-bg {
					background-color: #f6f9fb;
				}
				.card-content-center {
					width: 40.3125vw;
					height: 450px;
					position: relative;
					left: 41.9792vw;
					bottom: 0;
				}
				.card-content {
					padding-top: 60px;
					.card-content-logo {
						width: 89px;
						height: 89px;
						margin-bottom: 14px;
					}
					.card-content-name {
						font-weight: 600;
						font-size: 18px;
						color: #333333;
						line-height: 26px;
					}
					.card-content-desc {
						font-weight: 400;
						font-size: 14px;
						color: #666666;
						line-height: 20px;
						margin: 12px 0 37px 0;
					}
					.card-content-keywordList {
						display: flex;
						.keyword {
							display: flex;
							align-items: center;
							margin-right: 48px;
							img {
								width: 20px;
								height: 20px;
							}
							span {
								font-weight: 400;
								font-size: 16px;
								color: #333333;
								line-height: 23px;
								margin-left: 3px;
							}
						}
					}
					.card-content-btn {
						position: absolute;
						right: 0;
						bottom: 90px;
						display: flex;
						justify-content: flex-end;
						align-items: center;
						.btn-left,
						.btn-right {
							width: 48px;
							height: 48px;
							border-radius: 50%;
							background: #ffffff;
							box-shadow: 0px 4px 10px 0px rgba(51, 51, 51, 0.08);
							text-align: center;
							line-height: 48px;
							font-size: 24px;
							margin: 0 8px;
							cursor: pointer;
							&:hover {
								background-color: rgba(102, 102, 102, 0.1);
							}
						}
					}
				}
			}
		}
	}
	.case-768-content {
		width: 100%;
		height: 416px;
		.container {
			width: 100%;
			height: 100%;
			box-shadow: 0px 4px 10px 0px rgba(29, 103, 232, 0.1);
			border-radius: 8px;
			overflow: hidden;
			.card {
				width: 100%;
				height: 416px;
				border-radius: 8px;
				.card-img {
					width: 100%;
					height: 182px;
				}

				.card-content-center {
					width: calc(100% - 24px);
					height: 234px;
					background-color: #ffffff;
					padding: 0 12px;
				}
				.card-content {
					position: relative;
					padding-top: 35px;
					.card-content-logo {
						width: 104px;
						height: 46px;
						background-color: #ffffff;
						box-shadow: 0px 4px 10px 0px rgba(51, 51, 51, 0.1);
						text-align: center;
						position: absolute;
						left: 0;
						top: -23px;
						img {
							width: 45px;
							height: 45px;
						}
					}
					.card-content-name {
						font-weight: 550;
						font-size: 16px;
						color: #333333;
						line-height: 23px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.card-content-desc {
						font-weight: 400;
						font-size: 12px;
						color: #666666;
						line-height: 17px;
						margin: 8px 0 16px 0;
					}
					.card-content-keywordList {
						display: flex;
						justify-content: space-between;
						.keyword {
							img {
								display: block;
								width: 16px;
								height: 16px;
							}
							span {
								font-weight: 400;
								font-size: 12px;
								color: #333333;
								line-height: 17px;
								display: inline-block;
								max-width: 108px;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
					}
					.card-content-btn {
						position: absolute;
						right: 0;
						bottom: 90px;
						display: flex;
						justify-content: flex-end;
						align-items: center;
						.btn-left,
						.btn-right {
							width: 48px;
							height: 48px;
							border-radius: 50%;
							background: #ffffff;
							box-shadow: 0px 4px 10px 0px rgba(51, 51, 51, 0.08);
							text-align: center;
							line-height: 48px;
							font-size: 24px;
							margin: 0 8px;
							cursor: pointer;
							&:hover {
								background-color: rgba(102, 102, 102, 0.1);
							}
						}
					}
				}
			}
		}
	}
}

.title {
	width: 100%;
	margin-bottom: 60px;
	text-align: center;
	.title-text {
		font-size: 36px;
		font-weight: 600;
		color: #333333;
		line-height: 52px;
		display: block;
	}
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
	.case-warp {
		height: 468px;
		padding: 18px 12px;
		background-color: #f6f9fb;
		margin-bottom: 32px;
		.title {
			margin-bottom: 24px;
			.title-text {
				height: 24px;
				font-weight: 600;
				font-size: 24px;
				color: #333333;
				line-height: 24px;
			}
		}
	}
}
@media screen and (max-width: 520px) {
}
</style>
